﻿import Konva from 'konva';
//import { Stage } from 'konva/lib/Stage';
import { v4 as uuidv4 } from 'uuid';

const stageIdPrefix = 'dd-';

class Diagram {
    stageId = null;
    stage = null;
    zoneLayer = null;
    svgLayer = null;
    bubbleLayer = null;
    bubbleMap = new Map();
    constructor(stageId, stage, svgLayer, zoneLayer, bubbleLayer) {
        this.stageId = stageId,
        this.stage = stage;
        this.zoneLayer = zoneLayer;
        this.svgLayer = svgLayer;
        this.bubbleLayer = bubbleLayer;
    }
}

var stageWidth = 1500; //window.innerWidth;
var stageHeight = 1100; //window.innerHeight;
var containerName = 'container1';
var damageBubbleRadius = 25;
var moveScale = 2;

var scale, scaleX, scaleY;

var Diagrams = new Map();

var lastBubbleIsInside;
var currentZone;

const eventTarget = new EventTarget();

window.addEventListener('resize', fitStages());

window.DamageDiagram = {
    dotNetRef: null,
    InitDiagram: function (dotNetObjectReference, vehicleId, settings, damageBubbles, svgString, lockDiagram=false){
        DamageDiagram.dotNetRef = dotNetObjectReference;
        InitDamageDiagram(vehicleId, settings, svgString);
        LoadDamageBubbles(vehicleId, damageBubbles);
        if (lockDiagram) { LockUnlockBubbles(vehicleId, lockDiagram); }
        eventTarget.addEventListener('OnDamageBubbleIntersection', function (event) {
            DamageDiagram.dotNetRef.invokeMethodAsync('OnBubbleIntersection', event.detail);
        });
    },
    InsertNewBubble: function (damageBubble) {
        LoadDamageBubbles(damageBubble.vehicleId,[damageBubble])
    },
    DeleteUnusedDamageBubbles: function (stageId) {
        let deletedBubbleIds = DeleteUnusedDamageBubbles(stageId);
        return deletedBubbleIds;
    },
    LockBubbles: function (stageId, lockBubbles) {
        LockUnlockBubbles(stageId, lockBubbles);
    },
    RequestAsImage: function (stageId, imageType, pixelRatio, jpgQuality) {
        return getStageAsBase64(stageId, imageType, pixelRatio, jpgQuality);
    },
    RemoveStage: function (stageId) {
        RemoveStage(stageId);
    },
    FitStages: function () {
        fitStages();
    },
}


function InitDamageDiagram(vehicleId, settings, baseSvgString) {

    stageWidth = settings.pixelWidth;
    stageHeight = settings.pixelHeight;
    containerName = stageIdPrefix + vehicleId;
    moveScale = settings.damageBubbleScaleOnDrag;
    damageBubbleRadius = settings.damageBubbleDiameter / 2;


    let stage = new Konva.Stage({
        id: containerName,
        container: containerName,
        width: stageWidth,
        height: stageHeight,
    });

   

    let bubbleLayer = new Konva.Layer();
    stage.add(bubbleLayer);

    let svgLayer = new Konva.Layer();
    stage.add(svgLayer);

    var bk = new Konva.Rect({
            x: 0,
            y: 0,
            width: stageWidth,
            height: stageHeight,
            fill: 'white',
        });
    svgLayer.add(bk);

    bubbleLayer.zIndex(1);
    svgLayer.zIndex(0);

    let zoneLayer = new Konva.Layer();
    stage.add(zoneLayer);
    zoneLayer.zIndex(0);

    const url = svgToURL(baseSvgString);
    Konva.Image.fromURL(url, (image) => {
        image.scaleX = 1;
        image.scaleY = 1;
        svgLayer.add(image);
        svgLayer.draw();
    });

    //now parse the zones
    let zones = parseSvgZones(baseSvgString);
    zones.forEach((zone, zoneid) => {
        var path = new Konva.Path({
            data: zone,
            id: zoneid,
        });
        zoneLayer.add(path);
    });

    let newDiagram = new Diagram(containerName, stage, svgLayer, zoneLayer, bubbleLayer);
    Diagrams.set(newDiagram.stageId, newDiagram);
    //attach mouse events
    AttachMouseEvents(bubbleLayer);
    fitStageIntoParentContainer(newDiagram);
    // adapt the stage on any window resize
    window.addEventListener('resize', fitStages); // fitStageIntoParentContainer());
    //fitStages();
}

function RemoveStage(vehicleId) {
    let stage = Diagrams.get(stageIdPrefix + vehicleId);
    stage.destroy();
    Diagrams.delete(vehicleId);
}

function LoadDamageBubbles(vehicleId, damageBubbles) {
    if (damageBubbles !== null) {
        let diagram = Diagrams.get(stageIdPrefix + vehicleId);
        damageBubbles.forEach((bubble) => {
            diagram.bubbleLayer.add(createDamageBubble(damageBubbleRadius, bubble.fillColor, bubble.x, bubble.y, bubble.id));
            diagram.bubbleMap.set(bubble.id, bubble);
        });
        diagram.bubbleLayer.draw();
    }
}

function LockUnlockBubbles(vehicleId, lockBubbles) {
    //layer.draggable = lockBubbles;
    let bubbleLayer = Diagrams.get(stageIdPrefix + vehicleId).bubbleLayer;
    bubbleLayer.getChildren().forEach((node) => { node.draggable(!lockBubbles) });
}
/**
 * finds the zones in the svg
 * Looks only within paths
 * The zones' ids must start with "Z-"
 * @param {any} svgText
 * @returns {Map} - map of zones
 */
function parseSvgZones(svgText) {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(svgText, "text/xml");
    //debugger;
    let paths = Array.from(xmlDoc.getElementsByTagName("path"));
    const map = new Map();
    paths.forEach((path) => {
        try {
            let pathId = path.attributes["id"].value;
            if (pathId.startsWith("Z-")) {
                map.set(pathId, path.attributes["d"].value);
            }
        }
        catch (e) {

        }

    });
    return map;
}

function fitStages() {
    Diagrams.forEach((diagram) => { fitStageIntoParentContainer(diagram);})
}

/**
 * This will resize the stage based on parent container size
 * Both x and y scaling
 * */
function fitStageIntoParentContainer(stageDiagram) {
    //let id = '#dd';
    //id += stage.stageId;
    let myStage = document.querySelector('#' + stageDiagram.stageId); //document.querySelector('#stage-parent');
    if (myStage === null) {
        return;
    }
    let container = myStage.parentElement;
    /*if (container.clientWidth == 0 || container.clientWidth === undefined) {
        container = container.parentElement;
    }
    */

    // now we need to fit stage into parent
    var containerWidth = container.clientWidth;//window.innerWidth;
    // to do this we need to scale the stage
    scaleX = containerWidth / stageWidth;

    // now we need to fit stage into parent
    var containerHeight = container.clientHeight;//window.innerHeight;
    //containerHeight = containerHeight - heightBuffer;//hack?
    // to do this we need to scale the stage
    scaleY = containerHeight / stageHeight;
    //console.log(containerWidth + " x " + containerHeight);
    // uncomment to enable "uniform stretch"
    scale = scaleX = scaleY = Math.min(scaleX,scaleY);

    stageDiagram.stage.width(stageWidth * scaleX);
    stageDiagram.stage.height(stageHeight * scaleY);
    stageDiagram.stage.scale({ x: scaleX, y: scaleY });
    stageDiagram.stage.draw();
}

//PreventDefault is weird
/**
 * Create a new damage bubble
 * @param {number} radius
 * @param {any} fillColor
 * @return {Konva.Circle} a Konva Circle Object.
 */
function createDamageBubble(radius,
                            fillColor,
                            xPos = null,
                            yPos = null,
                            id = null) {
    var circle = new Konva.Circle({
        id: id ?? uuidv4(),
        x: xPos ?? stage.width() / 2,
        y: yPos ?? stage.height() / 2,
        radius: radius,
        fill: fillColor,
        stroke: 'black',
        strokeWidth: 1,
        opacity: .5,
        draggable: true,
        preventDefault: true,
    });
    return circle
}


function AttachMouseEvents(layerToAttachTo) {
    layerToAttachTo.on('mouseover touchstart', function (e) {
        //debugger;
        var target = e.target;//.findAncestor('Group');
        target.to({
            scaleX: moveScale,
            scaleY: moveScale,
            duration: .25,
            easing: Konva.Easings.BackEaseOut
        });
    });

    layerToAttachTo.on('mousedown', function (e) {
        //debugger;
        //var target = e.target.findAncestor('Group');
        var target = e.target;//.findAncestor('Group');
        target.to({
            scaleX: moveScale,
            scaleY: moveScale,
            duration: .25,
            easing: Konva.Easings.BackEaseOut
        });
    });

    layerToAttachTo.on('mouseout touchend mouseup', function (e) {
        //debugger;
        var target = e.target;//.findAncestor('Group');
        target.to({
            scaleX: 1,
            scaleY: 1,
            duration: .25,
            easing: Konva.Easings.EaseOut
        });
    });

    layerToAttachTo.on('dragend', function (e) {
        //debugger;
        var target = e.target;
        var diagram = Diagrams.get(target.getStage().attrs.id);
        target.to({
            scaleX: 1,
            scaleY: 1,
            duration: .5,
            easing: Konva.Easings.BounceEaseOut
        });
        if (lastBubbleIsInside) {
            let m = getShapeCenterPoint(target)
            let inverse = 1 / scale;
            eventTarget.dispatchEvent(new CustomEvent('OnDamageBubbleIntersection', {
                detail: {
                    X: m['X']* inverse,
                    Y: m['Y']* inverse,
                    ZoneId: currentZone,
                    Id: target.attrs.id,
                    FillColor: target.attrs.fill,
                    DamageType: diagram.bubbleMap.get(target.attrs.id).damageType,
                    VehicleId: diagram.bubbleMap.get(target.attrs.id).vehicleId
                }
            }));
        }
        else {
            target.fill('grey');
            BubbleMap.get(target.attrs.id).ZoneId = null;
            //target.attrs.id //update bubble?
        }

    });


    layerToAttachTo.on('dragmove', function (e) {
        //debugger;
        var target = e.target;
        var diagram = Diagrams.get(target.getStage().attrs.id);
        if (IsBubbleInsideZone(diagram.zoneLayer, target)) {
            lastBubbleIsInside = true;
            target.fill(diagram.bubbleMap.get(target.attrs.id).fillColor);
            diagram.bubbleMap.get(target.attrs.id).zoneId = currentZone;
        }
        else {
            target.fill('grey');
            lastBubbleIsInside = true; //set to tru so it always triggers event that was changes. From Zone To Null Zone
            diagram.bubbleMap.get(target.attrs.id).zoneId = null;
        }
        return;


    });
}

function DeleteUnusedDamageBubbles(vehicleId) {
    let newBubbleMap = new Map();
    let deletedBubbles = [];
    let diagram = Diagrams.get(stageIdPrefix + vehicleId);
    diagram.bubbleMap.forEach((bubble, key) => {
        if (bubble.zoneId === null || bubble.zoneId === undefined) {
            let bubbleNode = diagram.bubbleLayer.findOne("#".concat(key));
            if (bubbleNode !== undefined) {
                bubbleNode.destroy();
                deletedBubbles.push(bubble.id);
            }
        }
        else {
            newBubbleMap.set(bubble.id, bubble);
        }
    });

    diagram.bubbleLayer.draw();
    diagram.bubbleMap = newBubbleMap;
    return deletedBubbles;
}



/**
 * This will convert a string SVG to an image for Konva
 * @param {string} svgString
 */
function svgToURL(svgString) {
    const uri = window.btoa(unescape(encodeURIComponent(svgString)));
    return 'data:image/svg+xml;base64,' + uri;
}

function getShapeCenterPoint(shape) {
    let targetRect = shape.getClientRect();
    let x = targetRect.x + targetRect.width / 2;
    let y = targetRect.y + targetRect.height / 2;
    let m = new Map();
    m["X"] = x;
    m["Y"] = y;
    return m;
}


function IsBubbleInsideZone(damageZonesLayer, damageBubble) {
    let m = getShapeCenterPoint(damageBubble);
    let x = m["X"];
    let y = m["Y"];
    let results = damageZonesLayer.getIntersection({ x: x, y: y });

    if (results != null) {
        currentZone = results.attrs.id;
        return true;
    }
    else {
        currentZone = null;
        return false;
    }
}

function getStageAsBase64(stageId, imageType, pixelRatio=1, quality=0.0) {
    return Diagrams.get(stageIdPrefix + stageId).stage.toDataURL(
        {
            mimeType: imageType,
            pixelRatio: pixelRatio,
            quality: quality
        });
}